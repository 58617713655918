import { useState, useCallback } from 'react';
import { collection, addDoc, query, where, orderBy, getDocs, Timestamp, doc, setDoc, limit, runTransaction } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Message } from '../types';
import { createAssistant, createThread, streamMessage } from '../lib/openai';

export function useAssistant() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const initializeAssistant = useCallback(async (name: string) => {
    if (!currentUser) throw new Error('User not authenticated');

    try {
      setLoading(true);
      setError(null);

      // Use a transaction to ensure atomic initialization
      const result = await runTransaction(db, async (transaction) => {
        // Initialize messages collection if it doesn't exist
        const messagesRef = doc(db, 'messages', currentUser.uid);
        const messagesDoc = await transaction.get(messagesRef);

        if (!messagesDoc.exists()) {
          transaction.set(messagesRef, {
            initialized: true,
            createdAt: Timestamp.now()
          });

          // Create initial thread document
          const threadDocRef = doc(collection(db, 'messages', currentUser.uid, 'thread'), 'init');
          transaction.set(threadDocRef, {
            initialized: true,
            createdAt: Timestamp.now()
          });
        }

        // Create OpenAI assistant and thread
        const assistantId = await createAssistant(name);
        const threadId = await createThread();

        // Update user profile with assistant and thread IDs
        const userRef = doc(db, 'artists', currentUser.uid);
        transaction.set(userRef, {
          assistantId,
          threadId,
          updatedAt: Timestamp.now()
        }, { merge: true });

        return { assistantId, threadId };
      });

      return result;
    } catch (err) {
      console.error('Error initializing assistant:', err);
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const createNewThread = useCallback(async () => {
    if (!currentUser) throw new Error('User not authenticated');

    try {
      setLoading(true);
      setError(null);

      // Create new OpenAI thread
      const threadId = await createThread();

      // Update user profile with new thread ID
      await setDoc(doc(db, 'artists', currentUser.uid), {
        threadId,
        updatedAt: Timestamp.now()
      }, { merge: true });

      return threadId;
    } catch (err) {
      console.error('Error creating new thread:', err);
      setError(err as Error);
      throw new Error('Failed to create new conversation thread. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const fetchMessages = useCallback(async (threadId?: string) => {
    if (!currentUser) return [];

    try {
      setLoading(true);
      setError(null);

      // First ensure the messages collection exists
      const messagesRef = doc(db, 'messages', currentUser.uid);
      const messagesDoc = await getDocs(query(collection(db, 'messages'), where('__name__', '==', currentUser.uid)));

      if (!messagesDoc.docs.length) {
        // Initialize messages collection and thread subcollection
        await runTransaction(db, async (transaction) => {
          transaction.set(messagesRef, {
            initialized: true,
            createdAt: Timestamp.now()
          });

          // Create initial thread document
          const threadDocRef = doc(collection(db, 'messages', currentUser.uid, 'thread'), 'init');
          transaction.set(threadDocRef, {
            initialized: true,
            createdAt: Timestamp.now()
          });
        });

        return []; // Return empty array for new initialization
      }

      // Create base query for thread subcollection
      let q = query(
        collection(db, 'messages', currentUser.uid, 'thread'),
        orderBy('createdAt', 'desc')
      );

      // Add thread filter if provided
      if (threadId) {
        q = query(q, where('threadId', '==', threadId));
      }

      // Add limit to prevent loading too many messages
      q = query(q, limit(50));

      const querySnapshot = await getDocs(q);
      const messages = querySnapshot.docs
        .filter(doc => doc.id !== 'init') // Filter out initialization document
        .map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate().toISOString()
        })) as Message[];

      // Sort messages by date (oldest first)
      return messages.reverse();
    } catch (err) {
      console.error('Error fetching messages:', err);
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const sendMessageToAssistant = useCallback(async (
    threadId: string,
    assistantId: string,
    content: string,
    onStream: (content: string) => void,
    files?: File[]
  ) => {
    if (!currentUser) throw new Error('User not authenticated');

    try {
      setLoading(true);
      setError(null);

      // Ensure messages collection exists before saving messages
      const messagesRef = doc(db, 'messages', currentUser.uid);
      await runTransaction(db, async (transaction) => {
        const messagesDoc = await transaction.get(messagesRef);
        if (!messagesDoc.exists()) {
          transaction.set(messagesRef, {
            initialized: true,
            createdAt: Timestamp.now()
          });

          // Create initial thread document
          const threadDocRef = doc(collection(db, 'messages', currentUser.uid, 'thread'), 'init');
          transaction.set(threadDocRef, {
            initialized: true,
            createdAt: Timestamp.now()
          });
        }
      });

      // Save user message
      const userMessageRef = doc(collection(db, 'messages', currentUser.uid, 'thread'));
      await setDoc(userMessageRef, {
        userId: currentUser.uid,
        threadId,
        role: 'user',
        content,
        createdAt: Timestamp.now()
      });

      // Create placeholder for assistant's response
      const assistantRef = doc(collection(db, 'messages', currentUser.uid, 'thread'));
      await setDoc(assistantRef, {
        userId: currentUser.uid,
        threadId,
        role: 'assistant',
        content: '',
        createdAt: Timestamp.now()
      });

      // Stream the response
      let finalContent = '';
      for await (const chunk of streamMessage(threadId, assistantId, content)) {
        if (chunk.type === 'partial') {
          onStream(chunk.content);
          finalContent = chunk.content;
        } else if (chunk.type === 'complete') {
          finalContent = chunk.content;
        }
      }

      // Update the assistant's message with the complete response
      await setDoc(assistantRef, {
        content: finalContent,
        updatedAt: Timestamp.now()
      }, { merge: true });

      return finalContent;
    } catch (err) {
      console.error('Error sending message:', err);
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  return {
    initializeAssistant,
    createNewThread,
    fetchMessages,
    sendMessageToAssistant,
    loading,
    error
  };
}