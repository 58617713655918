import React, { useState, useRef, useEffect } from 'react';
import { Send, Paperclip, Bot, User, Loader } from 'lucide-react';
import { Message } from '../types';
import { useProfile } from '../contexts/ProfileContext';

type AssistantChatProps = {
  messages: Message[];
  onSendMessage: (content: string, onStream: (content: string) => void, files?: File[]) => Promise<void>;
  loading?: boolean;
};

export default function AssistantChat({ messages, onSendMessage, loading }: AssistantChatProps) {
  const { profile } = useProfile();
  const [input, setInput] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [streamingContent, setStreamingContent] = useState('');
  const [sending, setSending] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, streamingContent]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim() && selectedFiles.length === 0) return;

    try {
      setSending(true);
      setStreamingContent('');
      const currentInput = input; // Store current input
      setInput(''); // Clear input immediately
      await onSendMessage(currentInput, setStreamingContent, selectedFiles);
      setSelectedFiles([]); // Clear files after sending
    } catch (err) {
      console.error('Error sending message:', err);
      // Restore input if sending fails
      setInput(input);
    } finally {
      setSending(false);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    setSelectedFiles(prev => [...prev, ...files]);
  };

  const handleRemoveFile = (index: number) => {
    setSelectedFiles(prev => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="flex flex-col h-full bg-white rounded-lg border border-gray-200">
      {/* Messages Area */}
      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {/* Welcome Message */}
        {messages.length === 0 && !streamingContent && !sending && (
          <div className="text-center py-8">
            <Bot className="h-12 w-12 text-indigo-600 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">
              Hello, {profile.name}!
            </h3>
            <p className="text-gray-500">
              I'm your personal studio assistant. I can help you with tasks, answer questions,
              and provide guidance for your art business. What can I help you with today?
            </p>
          </div>
        )}

        {/* Message List */}
        {messages.map((message) => (
          <div
            key={message.id}
            className={`flex items-start gap-3 ${
              message.role === 'assistant' ? 'flex-row' : 'flex-row-reverse'
            }`}
          >
            <div className={`p-2 rounded-full ${
              message.role === 'assistant' 
                ? 'bg-indigo-100 text-indigo-600'
                : 'bg-gray-100 text-gray-600'
            }`}>
              {message.role === 'assistant' ? (
                <Bot className="h-5 w-5" />
              ) : (
                <User className="h-5 w-5" />
              )}
            </div>
            <div className={`flex-1 ${
              message.role === 'assistant' ? 'mr-12' : 'ml-12'
            }`}>
              <div className={`p-4 rounded-lg ${
                message.role === 'assistant'
                  ? 'bg-gray-100 text-gray-900'
                  : 'bg-indigo-600 text-white'
              }`}>
                <p className="whitespace-pre-wrap">{message.content}</p>
              </div>
              {message.files && message.files.length > 0 && (
                <div className="mt-2 space-y-2">
                  {message.files.map((file) => (
                    <a
                      key={file.id}
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center gap-2 px-3 py-1 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
                    >
                      <Paperclip className="h-4 w-4" />
                      {file.name}
                    </a>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}

        {/* Streaming Response */}
        {(streamingContent || sending) && (
          <div className="flex items-start gap-3">
            <div className="p-2 rounded-full bg-indigo-100 text-indigo-600">
              <Bot className="h-5 w-5" />
            </div>
            <div className="flex-1 mr-12">
              <div className="p-4 rounded-lg bg-gray-100 text-gray-900">
                {streamingContent ? (
                  <p className="whitespace-pre-wrap">{streamingContent}</p>
                ) : (
                  <div className="flex items-center gap-2">
                    <Loader className="h-4 w-4 animate-spin" />
                    <span>Thinking...</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <div ref={messagesEndRef} />
      </div>

      {/* Input Area */}
      <div className="border-t p-4">
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Selected Files */}
          {selectedFiles.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {selectedFiles.map((file, index) => (
                <div
                  key={index}
                  className="inline-flex items-center gap-2 px-3 py-1 bg-gray-100 rounded-lg"
                >
                  <span className="text-sm text-gray-700">{file.name}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveFile(index)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
          )}

          <div className="flex items-end gap-4">
            <div className="flex-1">
              <textarea
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type your message..."
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 resize-none"
                rows={3}
                disabled={sending}
              />
            </div>
            <div className="flex flex-col gap-2">
              <input
                ref={fileInputRef}
                type="file"
                onChange={handleFileSelect}
                className="hidden"
                multiple
                disabled={sending}
              />
              <button
                type="button"
                onClick={() => fileInputRef.current?.click()}
                className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-lg disabled:opacity-50"
                disabled={sending}
                title="Attach files"
              >
                <Paperclip className="h-5 w-5" />
              </button>
              <button
                type="submit"
                disabled={sending || (!input.trim() && selectedFiles.length === 0)}
                className="p-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
              >
                {sending ? (
                  <Loader className="h-5 w-5 animate-spin" />
                ) : (
                  <Send className="h-5 w-5" />
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}