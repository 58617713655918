import React, { useState, useEffect } from 'react';
import { Bot, Plus } from 'lucide-react';
import PageHeader from '../components/PageHeader';
import AssistantChat from '../components/AssistantChat';
import { useAssistant } from '../hooks/useAssistant';
import { useProfile } from '../contexts/ProfileContext';
import LoadingSpinner from '../components/LoadingSpinner';

export default function Assistant() {
  const { profile, updateProfile } = useProfile();
  const { initializeAssistant, createNewThread, fetchMessages, sendMessageToAssistant, loading } = useAssistant();
  const [messages, setMessages] = useState<any[]>([]);
  const [initializing, setInitializing] = useState(false);

  useEffect(() => {
    const loadMessages = async () => {
      try {
        // Initialize assistant if needed
        if (!profile.assistantId || !profile.threadId) {
          setInitializing(true);
          const { assistantId, threadId } = await initializeAssistant(profile.name);
          await updateProfile({
            assistantId,
            threadId
          });
          setInitializing(false);
        }

        // Load messages for current thread
        const data = await fetchMessages(profile.threadId);
        setMessages(data);
      } catch (err) {
        console.error('Error loading messages:', err);
      }
    };
    loadMessages();
  }, [profile, initializeAssistant, fetchMessages, updateProfile]);

  const handleNewThread = async () => {
    try {
      const threadId = await createNewThread();
      await updateProfile({ threadId });
      setMessages([]); // Clear messages for new thread
    } catch (err) {
      console.error('Error creating new thread:', err);
    }
  };

  const handleSendMessage = async (content: string, onStream: (content: string) => void, files?: File[]) => {
    if (!profile.assistantId || !profile.threadId) return;

    try {
      await sendMessageToAssistant(
        profile.threadId,
        profile.assistantId,
        content,
        onStream,
        files
      );

      // Refresh messages after sending
      const updatedMessages = await fetchMessages(profile.threadId);
      setMessages(updatedMessages);
    } catch (err) {
      console.error('Error sending message:', err);
    }
  };

  if (initializing) {
    return (
      <div className="min-h-[60vh] flex items-center justify-center">
        <div className="text-center">
          <Bot className="h-12 w-12 text-indigo-600 mx-auto mb-4 animate-bounce" />
          <h2 className="text-xl font-semibold text-gray-900 mb-2">
            Initializing Your Assistant
          </h2>
          <p className="text-gray-500">
            Please wait while we set up your personal studio assistant...
          </p>
        </div>
      </div>
    );
  }

  if (loading && messages.length === 0) {
    return <LoadingSpinner />;
  }

  return (
    <div className="h-[calc(100vh-8rem)] space-y-6">
      <PageHeader 
        title="Studio Assistant" 
        buttonLabel="New Thread"
        buttonIcon={Plus}
        onButtonClick={handleNewThread}
      />
      <div className="h-[calc(100%-5rem)]">
        <AssistantChat
          messages={messages}
          onSendMessage={handleSendMessage}
          loading={loading}
        />
      </div>
    </div>
  );
}