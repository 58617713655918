import React from 'react';
import { Outlet } from 'react-router-dom';
import { LayoutDashboard, Palette, Calculator, Calendar, UserCircle, Image, Briefcase, Building2, MessageSquare, BoxesIcon, Crown, Bot } from 'lucide-react';
import Sidebar from './Sidebar';

export default function Layout() {
  const navItems = [
    { icon: <LayoutDashboard size={24} />, label: 'Dashboard', path: '/app' },
    { icon: <Palette size={24} />, label: 'Art Inventory', path: '/app/inventory' },
    { icon: <BoxesIcon size={24} />, label: 'Collections', path: '/app/collections' },
    { icon: <Image size={24} />, label: 'Photos', path: '/app/gallery' },
    { icon: <Calculator size={24} />, label: 'Expenses', path: '/app/expenses' },
    { icon: <Calendar size={24} />, label: 'Tasks', path: '/app/tasks' },
    { icon: <Briefcase size={24} />, label: 'Opportunities', path: '/app/opportunities' },
    { icon: <Building2 size={24} />, label: 'Galleries & Fairs', path: '/app/galleries' },
    { icon: <MessageSquare size={24} />, label: 'Promotions', path: '/app/promotions' },
    { icon: <Bot size={24} />, label: 'Assistant', path: '/app/assistant' },
    { icon: <Crown size={24} />, label: 'Membership', path: '/app/membership' },
    { icon: <UserCircle size={24} />, label: 'Profile', path: '/app/profile' },
  ];

  return (
    <div className="flex h-screen bg-gray-50">
      <Sidebar navItems={navItems} />
      <main className="flex-1 overflow-auto">
        <div className="container mx-auto px-6 py-8">
          <Outlet />
        </div>
      </main>
    </div>
  );
}