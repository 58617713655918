import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  organization: import.meta.env.VITE_OPENAI_ORG_ID,
  dangerouslyAllowBrowser: true
});

type PromotionType = 'instagram' | 'blog' | 'pr';
type ToneOfVoice = 'playful' | 'informative' | 'excited' | 'artistic';

export async function createAssistant(name: string) {
  try {
    const assistant = await openai.beta.assistants.create({
      name: `${name}'s Studio Assistant`,
      instructions: `You are a personal AI assistant for ${name}, a professional artist. Help them manage their art business by:

1. Answering questions about art business management
2. Providing guidance on pricing, marketing, and selling artwork
3. Helping with task organization and time management
4. Offering creative suggestions and inspiration
5. Assisting with writing artist statements, bios, and descriptions
6. Providing technical advice about art materials and techniques

Use a professional yet friendly tone. When discussing financial matters, be clear and practical. For creative topics, be inspiring and encouraging.`,
      model: "gpt-4-1106-preview",
      tools: [
        {
          type: "code_interpreter"
        },
        {
          type: "function"
        }
      ]
    });

    return assistant.id;
  } catch (error) {
    console.error('Error creating assistant:', error);
    throw new Error('Failed to create assistant. Please try again.');
  }
}

export async function createThread() {
  try {
    const thread = await openai.beta.threads.create();
    return thread.id;
  } catch (error) {
    console.error('Error creating thread:', error);
    throw new Error('Failed to create conversation thread. Please try again.');
  }
}

export async function* streamMessage(threadId: string, assistantId: string, content: string) {
  if (!threadId || !assistantId || !content.trim()) {
    throw new Error('Invalid parameters for message streaming');
  }

  let run;
  try {
    // Add the message to the thread
    await openai.beta.threads.messages.create(threadId, {
      role: 'user',
      content
    });

    // Run the assistant
    run = await openai.beta.threads.runs.create(threadId, {
      assistant_id: assistantId
    });

    // Stream the response
    let responseText = '';
    let lastCheckTime = Date.now();
    const pollInterval = 1000; // 1 second
    const maxRetries = 3;
    let retries = 0;

    while (true) {
      try {
        // Wait for the polling interval
        const now = Date.now();
        const timeToWait = Math.max(0, pollInterval - (now - lastCheckTime));
        if (timeToWait > 0) {
          await new Promise(resolve => setTimeout(resolve, timeToWait));
        }
        lastCheckTime = Date.now();

        // Check run status
        const runStatus = await openai.beta.threads.runs.retrieve(threadId, run.id);
        
        if (runStatus.status === 'completed') {
          // Get the final response
          const messages = await openai.beta.threads.messages.list(threadId);
          const assistantMessage = messages.data.find(msg => 
            msg.role === 'assistant' && msg.run_id === run.id
          );
          
          if (assistantMessage && assistantMessage.content[0]?.type === 'text') {
            const finalContent = assistantMessage.content[0].text.value;
            yield { type: 'complete', content: finalContent };
          } else {
            throw new Error('Invalid response format from assistant');
          }
          break;
        } else if (runStatus.status === 'failed') {
          throw new Error(`Assistant run failed: ${runStatus.last_error?.message || 'Unknown error'}`);
        } else if (runStatus.status === 'cancelled') {
          throw new Error('Assistant run was cancelled');
        } else if (runStatus.status === 'expired') {
          throw new Error('Assistant run expired');
        } else if (runStatus.status === 'in_progress') {
          // Get latest message
          const messages = await openai.beta.threads.messages.list(threadId);
          const latestMessage = messages.data.find(msg => 
            msg.role === 'assistant' && msg.run_id === run.id
          );
          
          if (latestMessage && 
              latestMessage.content[0]?.type === 'text' && 
              latestMessage.content[0].text.value !== responseText) {
            responseText = latestMessage.content[0].text.value;
            yield { type: 'partial', content: responseText };
          }
        }

        // Reset retries on successful operation
        retries = 0;
      } catch (error) {
        console.error('Error during message streaming:', error);
        retries++;

        if (retries >= maxRetries) {
          throw new Error('Failed to stream message after multiple retries');
        }

        // Wait before retrying
        await new Promise(resolve => setTimeout(resolve, 1000 * retries));
      }
    }
  } catch (error) {
    console.error('Error in streamMessage:', error);

    // Try to cancel the run if it exists
    if (run?.id) {
      try {
        await openai.beta.threads.runs.cancel(threadId, run.id);
      } catch (cancelError) {
        console.error('Error cancelling run:', cancelError);
      }
    }

    throw new Error('Failed to process message. Please try again.');
  }
}

export async function generatePromotion(
  type: PromotionType,
  tone: ToneOfVoice,
  artwork?: { 
    title: string; 
    description: string; 
    materials: string[]; 
    price: number; 
    measurements?: any;
    shopLink?: string;
    currency?: { code: string; symbol: string; }
  },
  collection?: { 
    name: string; 
    description: string; 
    artistStatement?: string 
  },
  followUpPrompt?: string
) {
  try {
    let prompt = '';
    let maxTokens = 0;

    // Build context from artwork or collection details
    const context = artwork
      ? `Artwork Details:
Title: ${artwork.title}
Description: ${artwork.description}
Materials: ${artwork.materials.join(', ')}
Price: ${artwork.currency?.symbol || '$'}${artwork.price}
${artwork.measurements ? `Measurements: ${Object.entries(artwork.measurements)
  .filter(([_, value]) => value)
  .map(([key, value]) => `${key}: ${value}`)
  .join(', ')}` : ''}
${artwork.shopLink ? `Shop Link: ${artwork.shopLink}` : ''}`
      : collection
      ? `Collection Details:
Name: ${collection.name}
Description: ${collection.description}
${collection.artistStatement ? `Artist Statement: ${collection.artistStatement}` : ''}`
      : '';

    // Add tone instruction
    const toneInstruction = {
      playful: "Use a light-hearted, fun, and engaging tone with playful language and emojis.",
      informative: "Use a professional, educational tone focusing on details and craftsmanship.",
      excited: "Use an enthusiastic, energetic tone with emphasis on uniqueness and appeal.",
      artistic: "Use a poetic, creative tone emphasizing artistic vision and inspiration."
    }[tone];

    // Build type-specific prompt
    switch (type) {
      case 'instagram':
        prompt = `Create an engaging Instagram post for:
${context}

Tone Instructions: ${toneInstruction}

Format the response as follows:
Main caption with emojis

[Double line break]

Dimensions (if provided)

[Double line break]

${artwork?.shopLink ? `Shop now: ${artwork.shopLink}

[Double line break]` : ''}

Relevant hashtags (at least 15)

${followUpPrompt || ''}

Note: Exclude any quote marks, numbers, or bullet points from the response.`;
        maxTokens = 300;
        break;

      case 'blog':
        prompt = `Write a detailed blog post about:
${context}

Tone Instructions: ${toneInstruction}

Include:
Engaging introduction
Technical details and craftsmanship
Artistic inspiration
Materials and process
Significance and meaning
${artwork?.shopLink ? `Call to action with shop link: ${artwork.shopLink}` : ''}

${followUpPrompt || ''}

Note: Write in a natural flow without numbers or bullet points.`;
        maxTokens = 1000;
        break;

      case 'pr':
        prompt = `Write a professional press release about:
${context}

Tone Instructions: ${toneInstruction}

Include:
Compelling headline
Location and date
Main announcement
Quotes from the artist
Background information
Contact information
${artwork?.shopLink ? `Where to purchase: ${artwork.shopLink}` : ''}

${followUpPrompt || ''}

Note: Format as a standard press release without numbers or bullet points.`;
        maxTokens = 800;
        break;
    }

    const response = await openai.chat.completions.create({
      model: "gpt-4",
      messages: [
        {
          role: "system",
          content: "You are a professional art marketing specialist who creates engaging content for artists. Write in a natural, authentic voice and avoid generic marketing language. Never use numbers, bullet points, or ordered lists in your responses."
        },
        {
          role: "user",
          content: prompt
        }
      ],
      max_tokens: maxTokens,
      temperature: 0.7,
    });

    return response.choices[0].message.content;
  } catch (error) {
    console.error('Error generating promotion:', error);
    throw new Error('Failed to generate promotion content. Please try again.');
  }
}