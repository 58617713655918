import { 
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword, 
  signOut,
  sendPasswordResetEmail,
  updateProfile,
  User
} from 'firebase/auth';
import { doc, setDoc, collection, Timestamp } from 'firebase/firestore';
import { auth, db } from './firebase';

export async function signUp(email: string, password: string, name: string) {
  try {
    // Create auth user
    const { user } = await createUserWithEmailAndPassword(auth, email, password);
    
    // Update profile with name
    await updateProfile(user, { displayName: name });

    // Create user document in Firestore
    await setDoc(doc(db, 'artists', user.uid), {
      name,
      email,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      currency: { code: 'USD', symbol: '$' },
      storageLimit: 500 * 1024 * 1024, // 500MB base storage
      storageBonus: 0,
      isPublic: false
    });

    // Initialize messages collection and thread subcollection
    await setDoc(doc(db, 'messages', user.uid), {
      initialized: true,
      createdAt: Timestamp.now()
    });

    // Create an empty document in the thread subcollection to initialize it
    const threadRef = collection(db, 'messages', user.uid, 'thread');
    await setDoc(doc(threadRef, 'init'), {
      initialized: true,
      createdAt: Timestamp.now()
    });

    return user;
  } catch (error: any) {
    console.error('Signup error:', error);
    throw new Error(error.message || 'Failed to create account');
  }
}

export async function signIn(email: string, password: string): Promise<User> {
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    return user;
  } catch (error: any) {
    console.error('Login error:', error);
    throw new Error('Invalid email or password');
  }
}

export async function logOut(): Promise<void> {
  try {
    // Clear any cached data or local state before signing out
    localStorage.clear();
    sessionStorage.clear();
    
    // Sign out from Firebase
    await signOut(auth);
  } catch (error: any) {
    console.error('Logout error:', error);
    // Even if there's an error, we want to clear local data
    localStorage.clear();
    sessionStorage.clear();
    
    // Rethrow a user-friendly error
    throw new Error('Failed to log out properly. Please refresh the page and try again.');
  }
}

export async function resetPassword(email: string): Promise<void> {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error: any) {
    console.error('Password reset error:', error);
    throw new Error('Failed to send password reset email. Please check your email address and try again.');
  }
}